body   {
  /* background-color:#e7d074 !important; */

}
.MuiButton-textPrimary-212{
    /* color: #6E2C00 !important; */
}

.title > * {
    /* color: #6E2C00 !important; */
  }

  .descripcion {
    background: transparent;
    /* color: #6E2C00 !important; */
  }